// ########################## [IMPORTANT LIBRARIES]
import { FC } from 'react';

// ########################## [TYPES]
import { ITextualDetail } from './types';

/**
 * This little component wraps a simple text and allow you to apply some style to it.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const TextualDetail: FC<ITextualDetail> = ({
  dataTest = '',
  className = '',
  children,
}) => {
  return (
    <span data-test={dataTest} className={className}>
      {children}
    </span>
  );
};
