import { createContext } from 'react';

import { ICompanyDetailsContext } from '@shippypro/foundation/settings/types';

/**
 * This context will provide all the necessary data for the `Company details` tabs
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const CompanyDetailsContext = createContext<ICompanyDetailsContext | null>(
  null,
);

export default CompanyDetailsContext;
