// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TYPES]
import {
  EntityType,
  ICompanyDetailsContext,
  CompanyDetails,
  CompanyDetailsOptionalProperties,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useGetUser } from '@web/features/_global/hooks/api/useGetUser';
import { useGetProfileDetails } from '@shippypro/foundation/settings/hooks/api/useGetProfileDetails';
import useLDFlag from '@web/hooks/useLDFlags';
import { useUpdateProfileDetails } from '@shippypro/foundation/settings/hooks/api/useUpdateProfileDetails';
import { useGetRelatedEntities } from '@shippypro/foundation/settings/hooks/api/useGetRelatedEntities';

/**
 * [HOOK] useInstanceCompanyDetailsContext returns every data to be rendered about Company details,
 * together with the actions to update those details on the `Company details` tab
 *
 * @return {ICompanyDetailsContext} - The data and actions on the Company details tab
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const useInstanceCompanyDetailsContext = (): ICompanyDetailsContext => {
  const { user } = useGetUser();
  const { settingsOnUiApp } = useLDFlag();

  // Get related company entity
  const { relatedEntities } = useGetRelatedEntities(user?.id, EntityType.User);
  const companyId = useMemo(() => {
    const companyEntity = relatedEntities.find(
      entity => entity.entity_type === EntityType.Company,
    );
    return companyEntity?.entity_id;
  }, [relatedEntities]);

  // ------ [ METHODS SETUP ] ------ //

  // BE call to get company details
  const {
    profileDetails,
    isLoading: isGetCompanyDetailsLoading,
    isFetching: isGetCompanyDetailsFetching,
  } = useGetProfileDetails(companyId!, EntityType.Company);

  const companyDetails = profileDetails as CompanyDetails | null;

  // BE call to update company details
  const { updateProfileDetails, isLoading: isUpdateCompanyDetailsLoading } =
    useUpdateProfileDetails(companyId!);

  const setCompanyDetails = useCallback(
    async (updatedDetails: CompanyDetailsOptionalProperties) => {
      const parsedDetailsWithAddress = {
        ...updatedDetails,
        address: {
          ...updatedDetails.address,
          country: updatedDetails.address?.country?.name,
          postal_code: updatedDetails.address?.postal_code?.value,
        },
      };
      if (settingsOnUiApp && companyId) {
        const result = await updateProfileDetails(
          updatedDetails.address ? parsedDetailsWithAddress : updatedDetails,
          EntityType.Company,
        );
        return result;
      } else return null;
    },
    [settingsOnUiApp, updateProfileDetails, companyId],
  );

  return useMemo<ICompanyDetailsContext>(
    () => ({
      companyDetails,
      isCompanyDetailsLoading:
        isGetCompanyDetailsLoading ||
        isUpdateCompanyDetailsLoading ||
        isGetCompanyDetailsFetching,
      isGetCompanyDetailsLoading,
      isUpdateCompanyDetailsLoading,
      methods: {
        setCompanyDetails,
      },
    }),
    [
      companyDetails,
      isGetCompanyDetailsLoading,
      isGetCompanyDetailsFetching,
      isUpdateCompanyDetailsLoading,
      setCompanyDetails,
    ],
  );
};
